<template>
<br><br><br><br>
 <MDBContainer>

   <MDBRow class="align-items-start">
      <h1>Launching Soon</h1>
      <p>
          Receive updates directly to your inbox.
     </p>

     <form>
        <!-- Name input -->
        <div class="form-outline mb-4">
            <input type="text" id="form5Example1" class="form-control" />
            <label class="form-label" for="form5Example1">Name</label>
        </div>

        <!-- Email input -->
        <div class="form-outline mb-4">
            <input type="email" id="form5Example2" class="form-control" />
            <label class="form-label" for="form5Example2">Email address</label>
        </div>

        <!-- Checkbox -->
        <div class="form-check d-flex justify-content-center mb-4">
            <input
            class="form-check-input me-2"
            type="checkbox"
            value=""
            id="form5Example3"
            checked
            />
            <label class="form-check-label" for="form5Example3">
            I have read and agree to the terms
            </label>
        </div>

        <!-- Submit button -->
        <button type="submit" class="btn btn-primary btn-block mb-4">Subscribe</button>
        </form>

  </MDBRow>
 


</MDBContainer>

</template>

<script>
import { MDBContainer, MDBRow } from 'mdb-vue-ui-kit';

export default {
  components: {
    MDBRow,MDBContainer
    
  },
};
</script>
